<template>
  <div>
<!--     <v-container fluid fill-height class="home-hero" :style="{'background-image': 'url(' + recipe.imgUrl + ')'}">
        <v-layout justify-center align-center column pa-5>
            <h1 class="display-4 font-weight-black black--text text-xs-center">{{ recipe.name }}</h1>
            <div class="font-weight-bold black--text text-xs-center">{{ recipe.description }}</div>
        </v-layout>
  </v-container> -->
  <v-breadcrumbs :items="breadcrumbItems"/>
  <v-card v-if="food">
    <v-img
      v-if="food.imageName"
      :src="imageCdnHost + '/' + food.imageName"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
      height="400px"
    >
      <v-card-title class="display-1" v-text="food.categoryId"></v-card-title>
      <v-card-title class="display-2" v-text="food.name"></v-card-title>
      <a class="white--text float-right mt-n10 mr-6" :href="food.wikipediaUrl" target="_blank">(c) photo Wikipedia</a>
    </v-img>
    <div v-if="!food.imageName">
      <v-card-title class="display-1" v-text="food.categoryId"></v-card-title>
      <v-card-title class="display-2" v-text="food.name"></v-card-title>
    </div>
    <v-container fluid v-if="food.usage"> 
      <v-row dense>
        <v-col cols="6" sm="4" md="4" lg="3">
          <v-card class="primary lighten-2">
            <v-card-title class="">Popularity ranking</v-card-title>
            <v-card-title class="display-1" v-text="'#' + food.usage.popularityIndex"></v-card-title>
            <v-card-actions>
              <v-btn text color="white" :to="'/recipes?foodId='+ food.id">View recipes</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="4" lg="3" v-if="food.usage.averageServingWeightG">
          <v-card class="primary lighten-4">
            <v-card-title class="">Average serving</v-card-title>
            <v-card-title class="display-1" v-text="food.usage.averageServingWeightG + 'g'"></v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    
    <v-card-text v-if="articles && articles.length">
      <ArticleGrid :items="articles" :title="'More on '+food.name" />
    </v-card-text>
    
    <v-card-text class="black--text" v-if="recipes && recipes.length">
      <h3 class="headline">Popular recipes containing {{ food.name }}</h3>
      <RecipeGrid :recipes="recipes" />
    </v-card-text>

    <!-- Food combos -->
    <v-card-text class="black--text" v-if="combinations && combinations.length">
      <h3 class="headline">Popular combinations</h3>
      <IngredientGrid :items="combinations" />
    </v-card-text>

    <!-- Nutrients -->
    <div class="black--text" v-if="food.values">
      <v-card-title class="headline">Nutrients (per 100g)</v-card-title>
        <v-simple-table fixed-header>
          <template v-slot:default>
            <thead>
              <tr class="">
                <th class="text-left green white--text">Nutrient</th>
                <th class="text-left green white--text">Value</th>
                <th class="text-left green white--text">% DV</th>
              </tr>
            </thead>
            <tbody>
                <template v-for="(nutrients, category) in nutrientsGroupedByCategory">
                <tr :key="category" class="">
                  <td colspan="6">
                    <strong>{{category}}</strong>
                  </td>
                </tr>
                <template v-for="(nutrient) in nutrients">
                <tr :key="nutrient.id">
                  <td><router-link tag="a" :to="{name: 'nutrient', params: {id: nutrient.id}}">{{ nutrient.name }}</router-link></td>
                  <td>{{ nutrient.value }} {{ nutrient.unit }}</td>
                  <td>
                    <span v-if="nutrient.dailyIntake">
                      {{ Math.round((nutrient.value / nutrient.dailyIntake) * 100)}}%
                    </span>
                  </td>
                </tr>
                </template>
                </template>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <v-card-text>
        <NutrientCopyright />
      </v-card-text>  
    </v-card>
  </div>
</template>
 
<script>
import RecipeGrid from "@/components/RecipeGrid.vue";
import ArticleGrid from "@/components/ArticleGrid.vue";
import IngredientGrid from "@/components/IngredientGrid.vue";
import NutrientCopyright from "@/components/NutrientCopyright.vue";
import foodService from "@/services/foodService";
import articleService from "@/services/articleService";
import { EventBus } from '@/plugins/eventbus.js';

export default {
  name: "Ingredient",
  components: {
    RecipeGrid,
    IngredientGrid,
    ArticleGrid,
    NutrientCopyright
  },
  props: {
  },
  data() {
    return {
      food: null,
      articles: null,
      recipes: null,
      combinations: null,
      imageCdnHost: process.env.VUE_APP_IMG_CDN_HOST
    };
  },
  methods: {
    async loadData(id) {
      var response = await foodService.get(id);
      console.log('response', response);
      this.food = response.data;

      if (this.food.slug && this.food.slug != id) {
        // redirect to page with slug as id when there is a slug but when it's not used
        this.$router.push({ name: 'ingredient', params: { id: this.food.slug } });
      }
      else {
        var meta = {
          title: this.food.name,
          //description: this.recipe.description,
          //image: this.food.imageName,
        };
        EventBus.$emit('page-header-change', meta);
        this.articles = await articleService.listByIngredient(id);
      }

      response = await foodService.getRecipes(this.food.id);
      this.recipes = response.data.data;

      response = await foodService.getCombinations(this.food.id);
      this.combinations = response.data.data;
    },
  },
  computed: {
    breadcrumbItems() {
      return this.food == null ? [] : [
        { text: 'Ingredients', href: '/ingredients' },
        { text: this.food.category.name, to: {name: 'ingredientCategory', params: {id: this.food.category.id}} },
        { text: this.food.name, disabled: true },
      ]
    },
    nutrientsGroupedByCategory(){
      return this.food.values.reduce((acc, item) => {
        (acc[item.category] = acc[item.category] || []).push(item)
        return acc
      }, {});
    },
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.loadData(to.params.id);
    next();
  },
  async mounted() {
    await this.loadData(this.$route.params.id);
  }
};
</script>

<style scoped>
</style>